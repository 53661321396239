<script setup lang="ts">
import { TabsContent, TabsList, TabsRoot, TabsTrigger } from 'radix-vue'
import type { Product } from '~/types'

defineProps<{ product: Product }>()

const numberFormatter = new Intl.NumberFormat('da-DK', {
  style: 'currency',
  currency: 'DKK',
  minimumFractionDigits: 0,
  maximumFractionDigits: 2,
})
</script>

<template>
  <div class="lg:grid lg:grid-cols-2 lg:items-start lg:gap-x-8">
    <template v-if="product.images && product.images.length > 0">
      <TabsRoot class="flex flex-col-reverse" :default-value="product.images[0].id.toString()">
        <div class="mx-auto mt-6 hidden w-full max-w-2xl sm:block lg:max-w-none">
          <TabsList class="grid grid-cols-4 gap-6">
            <TabsTrigger
              v-for="image in product.images"
              :key="image.id"
              :value="image.id.toString()"
              class="relative flex h-24 cursor-pointer items-center justify-center rounded-md bg-white text-sm font-medium uppercase text-gray-900 hover:bg-gray-50 focus:outline-none focus:ring focus:ring-opacity-50 focus:ring-offset-4 group"
            >
              <span class="sr-only">{{ image.name }}</span>
              <span class="absolute inset-0 overflow-hidden rounded-md">
                <img :src="image.src" alt="" class="h-full w-full object-cover object-center">
              </span>
              <span
                class="group-data-[state=active]:ring-blue-500 group-data-[state=inactive]:ring-transparent pointer-events-none absolute inset-0 rounded-md ring-2 ring-offset-2"
                aria-hidden="true"
              />
            </TabsTrigger>
          </TabsList>
        </div>

        <div class="aspect-h-1 aspect-w-1 w-full">
          <TabsContent
            v-for="image in product.images"
            :key="image.id"
            :value="image.id.toString()"
          >
            <img
              :src="image.src"
              :alt="image.alt"
              class="h-full w-full object-cover object-center sm:rounded-lg bg-gray-50"
            >
          </TabsContent>
        </div>
      </TabsRoot>
    </template>
    <div v-else class="bg-blue-100 w-full h-full rounded-lg" />

    <div class="mt-10 px-4 sm:px-0" :class="product.tagLine ? 'mt-2' : 'mt-10'">
      <p v-if="product.tagLine">
        {{ product.tagLine }}
      </p>
      <h1 class="text-3xl font-bold tracking-tight text-gray-900">
        {{ product.name }}
      </h1>

      <div class="mt-3">
        <h2 class="sr-only">
          Produktinformation
        </h2>

        <span v-if="product.fullPrice !== product.price" class="text-2xl tracking-tight text-gray-600 line-through mb-1">
          Normalpris: {{ numberFormatter.format(product.fullPrice) }}
        </span>
        <p class="text-3xl tracking-tight text-gray-900">
          <span v-if="product.fullPrice !== product.price">Nu: </span>{{ numberFormatter.format(product.price) }}
        </p>
      </div>

      <div class="mt-6">
        <h3 class="sr-only">
          Beskrivelse
        </h3>

        <div class="space-y-6 text-base text-gray-700 prose" v-html="product.description" />
      </div>

      <slot />
      <section aria-labelledby="details-heading" class="mt-12">
        <h2 id="details-heading" class="sr-only">
          Detaljer
        </h2>

        <template v-if="product.details">
          <AccordionRoot
            v-if="product.details.length > 0"
            class="divide-y divide-gray-200 border-t"
            :default-value="product.details[0].name"
            type="single"
            :collapsible="true"
          >
            <template v-for="detail in product.details" :key="detail.name">
              <AccordionItem :value="detail.name">
                <AccordionHeader class="flex">
                  <AccordionTrigger class="group relative flex w-full items-center justify-between py-6 text-left">
                    <span class="group-data-[state=open]:text-blue-600 group-data-[state=closed]:text-gray-900 text-sm font-medium">
                      {{ detail.name }}
                    </span>
                    <div>
                      <Icon
                        name="heroicons:chevron-down"
                        class="group-data-[state=open]:rotate-180 group-data-[state=open]:text-blue-500 transition-transform h-6 w-6 text-gray-400 group-hover:text-gray-500"
                        aria-hidden="true"
                      />
                    </div>
                  </AccordionTrigger>
                </AccordionHeader>
                <AccordionContent class="prose prose-sm pb-6">
                  <div v-html="detail.content" />
                </AccordionContent>
              </AccordionItem>
            </template>
          </AccordionRoot>
        </template>
      </section>
    </div>
  </div>
</template>
